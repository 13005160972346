@use "./variables" as *;

$field-height: 56px;
$field-height-sm: 44px;
$field-font-size-sm: 0.75rem;
$field-bg: $white;
$field-radius: 0.375rem;
$field-full-radius: 1.5rem;
$field-padding-x: 1rem;
$field-padding-y: 0.5rem;
$btn-font-size: 0.875rem;
$field-margin-bottom: 1rem;
$label-margin-bottom: 0.625rem;

.form {
  &__flexrow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: 639px) {
      display: block;
    }
    .form__group {
      width: calc(50% - 10px);
      @media screen and (max-width: 639px) {
        width: 100%;
      }
    }
  }
  &__group {
    margin-bottom: $field-margin-bottom;
    position: relative;
    &__flex {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .form__input {
        margin-right: 1rem;
        &:last-child {
          margin-right: 0;
        }
        @media screen and (max-width: 479px) {
          margin-right: 0.5rem;
        }
      }
    }
    &--btnflex {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row-reverse;
      margin-bottom: 0;
      @media screen and (max-width: 639px) {
        display: block;
      }
      .btn {
        width: calc(50% - 10px);
        @media screen and (max-width: 639px) {
          width: 100%;
          margin-bottom: 15px;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
    @media screen and (max-width: 639px) {
      margin-bottom: 0.938rem;
    }
    &__ouline {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: #f6f6f6;
      padding: $field-padding-y $field-padding-x;
      font-size: 0.875rem;
      max-width: 280px;
      width: 100%;
      height: 45px;
      border: 1px solid $primary;
      border-radius: $field-radius;
      color: $gray;
      .btnCopy {
        padding: 0;
        display: flex;
        align-items: center;
        gap: 5px;
        background: none;
        font-size: 0.625rem;
        font-weight: 700;
        color: $primary;
        border: none;
        outline: none;
        cursor: pointer;
      }
    }
    &__viewBox {
      display: flex;
      align-items: center;
      gap: 15px;
      .btnView {
        padding: 0;
        display: flex;
        align-items: center;
        gap: 5px;
        background: none;
        font-size: 0.625rem;
        font-weight: 700;
        color: $primary;
        border: none;
        outline: none;
        cursor: pointer;
      }
    }
    &--otp {
      justify-content: space-between;
      .form__input {
        padding: 0.5rem;
        font-size: 1.25rem;
        font-weight: 600;
        color: $primary;
        width: 44px;
        height: 44px;
        border-color: $primary;
        background: $white;
        text-align: center;
        @media (min-width: 639px) {
          padding: 0.5rem 1rem;
          font-size: 2rem;
          width: 56px;
          height: 56px;
        }
      }
    }
    .para {
      font-size: 1rem;
      font-weight: 400;
      color: $black;
      &--right {
        text-align: right;
      }
      &--center {
        text-align: center;
      }
      .link {
        display: inline-block;
        vertical-align: middle;
        text-decoration: none;
        color: $primary;
        &--underline {
          text-decoration: underline;
          &:disabled {
            opacity: 0.5;
            pointer-events: none;
          }
        }
      }
    }
    .btn {
      margin-top: 1.25rem;
      @media (max-width: 639px) {
        margin-top: 0.938rem;
      }
    }
  }
  &__groupField {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
    font-size: 0.875rem;
    font-weight: 300;
    width: 100%;
    height: $field-height-sm;
    padding: $field-padding-y $field-padding-x;
    border: 1px solid rgba($border, 35%);
    border-radius: $field-radius;
    background-color: $field-bg;
    color: $black;
    &--phonenumber {
      padding-left: 0;
    }
    .form__input {
      padding: 0;
      border: 0;
      flex: 1 1;
      height: auto;
      border-radius: 0;
    }
    .form__select {
      background-color: rgba($primary, 8%);
      background-size: 10px 6px;
      width: auto;
      max-width: 82px;
      border: 0;
      border-radius: 0;
      &:focus {
        background-color: rgba($primary, 20%);
      }
    }
    .btn {
      display: flex;
      align-items: center;
      background: none;
      padding: 0;
      border: none;
      cursor: pointer;
    }
    &--disabled {
      background: #d2d6db80;
      border: 1px solid #d2d6db80;
      cursor: not-allowed;
      .form__select {
        background-color: #d2d6db80;
        cursor: not-allowed;
      }
      .form__input {
        background: rgb(232, 234, 237);
        cursor: not-allowed;
      }
    }
  }
  &__paswordfield {
    position: relative;
    .btn {
      border: none;
      outline: none;
      padding: 0.5rem 1rem;
      position: absolute;
      width: 50px;
      height: 44px;
      right: 0;
      top: 0;
      z-index: 1;
      margin-top: 0 !important;
      cursor: pointer;
    }
    &--show {
      .btn {
        background: url("../../svg/view.svg") no-repeat center !important;
      }
    }
  }
  &__Field {
    position: relative;
    &--radioOption {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 16px;
      .form__label {
        font-size: 1.125rem;
        font-weight: 600;
        color: $primaryBlack;
        margin-bottom: 0;
      }
      .form__radio {
        gap: 16px;
        &__option {
          margin-right: 0;
        }
      }
    }
    &--focused {
      background-color: rgba($primary, 20%);
    }
    .ql-container.ql-snow {
      min-height: 120px;
    }
    &__group {
      position: relative;
      .form__input {
        padding-right: 2.75rem;
      }
    }
    &__icon {
      cursor: pointer;
      &.link {
        font-size: 0.875rem;
      }
    }
    .customSelect {
      position: relative;
      min-width: 1px;
      width: 100%;
      &--minAuto {
        min-width: 1px;
      }
      &__caption {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 16px;
        font-size: 0.875rem;
        font-weight: 400;
        width: 100%;
        height: $field-height-sm;
        padding: $field-padding-y $field-padding-x * 2.5 $field-padding-y
          $field-padding-x;
        border: 1px solid rgba($border, 35%);
        border-radius: $field-radius;
        background: $field-bg url("../../svg/down-arrow.svg") right 1rem center
          no-repeat;
        color: $black;
        cursor: pointer;
        text-transform: capitalize;
        &--disabled {
          background: #d2d6db80;
          border: 1px solid #d2d6db80;
          cursor: not-allowed;
        }
        &__placeholder {
          color: $gray;
        }
        &:focus {
          //background-color: rgba($primary, 20%);
          border-color: $primaryGray;
        }
      }
      &__dropdown {
        display: none;
        background: $white;
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        min-width: 340px;
        box-shadow: -1px -2px 9.8px 0px #0000001a;
        box-shadow: 0px 1px 2.2px 0px #00000040;
        z-index: 1;
        height: auto;
        max-height: 243px;
        overflow: hidden;
        overflow-y: auto;
        &--width450 {
          min-width: 450px;
        }
        &__header {
          background: #f0f4f6;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0.75rem 1.875rem;
          gap: 20px;
          .title {
            font-size: 0.875rem;
            font-weight: 500;
            color: $lightGray;
            margin-bottom: 0;
          }
        }
        &__body {
          background: $white;
          display: flex;
          flex-direction: column;
        }
        &__item {
          padding: 0.65rem 1.875rem;
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 20px;
          font-size: 0.75rem;
          font-weight: 400;
          color: $black;
          position: relative;
          cursor: pointer;
          text-transform: capitalize;
          &.selected,
          &:hover {
            background: rgba(#e1e1e1, 31%);
            &::after {
              content: "";
              background: url("../../svg/check-icon-black.svg");
              background-position: center center;
              background-repeat: no-repeat;
              position: absolute;
              width: 10px;
              height: 8px;
              left: 12px;
              top: 50%;
              transform: translate(0, -50%);
              z-index: 1;
            }
          }
          
        }
        &__noitem {
          padding: 0.65rem 1.875rem;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 20px;
          font-size: 0.75rem;
          font-weight: 400;
          color: $black;
          position: relative;
        }
        .col {
          &:nth-child(2) {
            width: 100px;
          }
          &--col3 {
            width: calc(100% / 3);
            &:nth-child(3) {
              width: 100px;
            }
          }
          &:last-child {
            text-align: left;
          }
        }
      }
      &.open {
        .customSelect__dropdown {
          display: block;
          z-index: 1000;
        }
      }
    }
  }
  &__input {
    font-size: 0.875rem;
    font-weight: 300;
    width: 100%;
    height: $field-height;
    padding: $field-padding-y $field-padding-x;
    border: 1px solid rgba($border, 35%);
    border-radius: $field-radius;
    background-color: $field-bg;
    color: $black;
    &--search {
      background: $white url("../../svg/search-icon.svg") no-repeat 15px center;
      padding-left: 2.813rem;
      border: 1px solid rgba($border, 35%);
    }
    &::-webkit-input-placeholder {
      color: $gray;
    }
    &::-moz-placeholder {
      color: $gray;
    }
    &:-ms-input-placeholder {
      color: $gray;
    }
    &:-moz-placeholder {
      color: $gray;
    }
    &--fullrounded {
      border-radius: $field-full-radius;
      padding: $field-padding-y $field-padding-x * 1.5;
    }
    &--bgGray {
      background: rgba(235, 235, 233, 0.5);
    }
    &--sm {
      height: $field-height-sm;
      font-size: $field-font-size-sm;
    }
    &--hidden {
      display: none;
      visibility: hidden;
      overflow: hidden;
    }
    &--disabled {
      background: #d2d6db80;
      border: 1px solid #d2d6db80;
      cursor: not-allowed;
    }
  }
  // Date picker
  &__date {
    font-size: 0.875rem;
    font-weight: 300;
    color: $gray;
    overflow: hidden;
    position: relative;
    width: 100%;
    height: $field-height;
    padding: $field-padding-y $field-padding-x * 2.5 $field-padding-y
      $field-padding-x;
    border: 1px solid rgba($border, 35%);
    border-radius: $field-radius;
    background: $field-bg url("../../svg/calender.svg") right 1rem center
      no-repeat;
    background-size: 16px;
    position: relative;
    text-transform: uppercase;
    z-index: 1;
    &--filled {
      font-size: 0.875rem;
      font-weight: 300;
      color: $black;
    }
    &--time {
      background: $field-bg url("../../svg/time.svg") right 1rem center
        no-repeat;
    }
    &--disabled {
      background: #d2d6db80;
      border: 1px solid #d2d6db80;
      cursor: not-allowed;
    }
    &::-webkit-input-placeholder {
      display: none;
      font-size: 0;
      color: $gray;
    }
    &::-moz-placeholder {
      display: none;
      font-size: 0;
      color: $gray;
    }
    &:-ms-input-placeholder {
      display: none;
      font-size: 0;
      color: $gray;
    }
    &:-moz-placeholder {
      display: none;
      font-size: 0;
      color: $gray;
    }
    &--sm {
      height: $field-height-sm;
    }

    &::-webkit-calendar-picker-indicator {
      background: transparent;
      color: transparent;
      cursor: pointer;
      height: auto;
      position: absolute;
      left: 0;
      bottom: 0;
      right: 0;
      top: 0;
      width: auto;
    }
  }
  &__select {
    font-size: 0.875rem;
    font-weight: 300;
    width: 100%;
    color: $black;
    height: $field-height;
    padding: $field-padding-y $field-padding-x * 2.5 $field-padding-y
      $field-padding-x;
    border: 1px solid rgba($border, 35%);
    border-radius: $field-radius;
    cursor: pointer;
    appearance: none;
    -webkit-appearance: none;
    background: $field-bg url("../../svg/down-arrow.svg") right 1rem center
      no-repeat;
    &--disabled {
      background-color: #d2d6db80;
      border: 1px solid #d2d6db80;
      cursor: not-allowed;
    }
    &::-webkit-select-placeholder {
      color: $gray;
    }
    &::-moz-placeholder {
      color: $gray;
    }
    &:-ms-select-placeholder {
      color: $gray;
    }
    &:-moz-placeholder {
      color: $gray;
    }
    &--bgGray {
      background-color: rgba(235, 235, 233, 0.5);
    }
    &--fullrounded {
      border-radius: $field-full-radius;
      padding: $field-padding-y $field-padding-x * 1.5;
    }

    &--sm {
      height: $field-height-sm;
    }
    &--half {
      width: 50%;
    }
    &--order {
      &:focus {
        border-color: $primaryGray;
      }
    }
  }
  &__textarea {
    font-size: 0.875rem;
    font-weight: 300;
    width: 100%;
    color: $black;
    min-height: 160px;
    padding: $field-padding-y $field-padding-x;
    border: 1px solid rgba($border, 35%);
    border-radius: $field-radius;
    background-color: $field-bg;
    &::-webkit-textarea-placeholder {
      color: $gray;
    }
    &::-moz-placeholder {
      color: $gray;
    }
    &:-ms-textarea-placeholder {
      color: $gray;
    }
    &:-moz-placeholder {
      color: $gray;
    }
    &--fullrounded {
      border-radius: $field-full-radius;
      padding: $field-padding-y $field-padding-x * 1.5;
    }
    &--disabled {
      background: #d2d6db80;
      border: 1px solid #d2d6db80;
      cursor: not-allowed;
    }
    &--sm {
      height: $field-height-sm;
    }
    &--xs {
      min-height: 100px;
    }
  }
  &__label {
    display: block;
    font-size: 0.875rem;
    font-weight: 400;
    color: $black;
    margin-bottom: $label-margin-bottom;
    @media screen and (min-width: 639px) {
      font-size: 1rem;
      margin-bottom: 0.375rem;
    }
    &--sm {
      font-size: $field-font-size-sm;
      margin-bottom: 0px;
    }
    &__asterisk {
      font-size: $field-font-size-sm;
      font-weight: 600;
      color: $danger;
      margin-left: 2px;
    }
    &--medium {
      font-weight: 500;
    }
  }
  &__radio {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    &__option {
      display: flex;
      align-items: center;
      position: relative;
      margin-right: 15px;
      margin-bottom: 10px;
    }
    &__label {
      position: relative;
      font-size: 1rem;
      color: $black;
      line-height: 22px;
      padding-left: 30px;
      cursor: pointer;
      &::before {
        content: "";
        position: absolute;
        width: 18px;
        height: 18px;
        left: 0;
        top: 2px;
        border: 1px solid rgba($border, 35%);
        border-radius: 50px;
      }
    }
    &__input {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      opacity: 0;
      margin: 0;
      z-index: 1;
      cursor: pointer;
      &:checked {
        + .form__radio__label {
          &::before {
            border-color: $primary;
          }
          &::after {
            content: "";
            background-color: $primary;
            display: inline-block;
            position: absolute;
            width: 10px;
            height: 10px;
            left: 0.34rem;
            top: 0.45rem;
            border-radius: 50px;
          }
        }
      }
    }
  }
  &__checkbox {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    &__option {
      display: flex;
      align-items: center;
      position: relative;
      margin-right: 20px;
      margin-bottom: 10px;
    }
    &__label {
      position: relative;
      font-size: 1rem;
      font-weight: 400;
      color: $primary;
      line-height: 20px;
      padding-left: 30px;
      cursor: pointer;
      &::before {
        content: "";
        position: absolute;
        width: 18px;
        height: 18px;
        left: 0;
        top: 0;
        border: 1px solid $primary;
        border-radius: 2px;
      }
    }
    &__input {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      opacity: 0;
      z-index: 1;
      margin: 0;
      cursor: pointer;
      &:checked {
        + .form__checkbox__label {
          &::before {
            background: $primary;
            border-color: $primary;
          }
          &::after {
            content: "";
            background: url("../../svg/check-icon.svg") no-repeat;
            background-position: center center;
            display: inline-block;
            position: absolute;
            width: 10px;
            height: 8px;
            left: 6px;
            top: 6px;
            border-radius: 50px;
          }
        }
      }
    }
    &--gray {
      .form__checkbox__label {
        color: $primaryBlack;
        &::before {
          border-color: rgba($border, 35%);
        }
      }
    }
    &--blue {
      .form__checkbox__option {
        margin-right: 30px;
        margin-bottom: 20px;
      }
      .form__checkbox__label {
        color: $primary;
        &::before {
          border-color: rgba($border, 35%);
        }
      }
    }
  }
  &__switchBtn {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 20px;
    &__option {
      display: flex;
      align-items: center;
      position: relative;
      gap: 20px;
      .form__label {
        margin-bottom: 0;
      }
      .switchBtn {
        display: flex;
        align-items: center;
        .toggle {
          opacity: 0;
          position: absolute;
          left: -99999px;
          & + label {
            font-size: .688rem;
            font-weight: 500;
            min-width: 58px;
            height: 24px;
            line-height: 24px;
            background-color: $dangerLight;
            padding: 0px 8px;
            border-radius: 50px;
            display: inline-block;
            position: relative;
            cursor: pointer;
            &:before {
              content: " ";
              position: absolute;
              top: 50%;
              left: 2px;
              width: 20px;
              height: 20px;
              background: $white;
              margin-top: -10px;
              border-radius: 50px;
              z-index: 2;
            }
            .off,
            .on {
              color: $white;
            }
            .off {
              margin-left: 20px;
              display: inline-block;
            }
            .on {
              display: none;
            }
          }
          &:checked + label {
            background-color: $success;
            .off {
              display: none;
            }
            .on {
              margin-right: 20px;
              display: inline-block;
            }
            &:before {
              background-position: 0 0;
              left: auto;
              right: 2px;
              margin-left: -26px;
            }
          }
        }
      }
    }
  }
  &__browsefile {
    background-color: $field-bg;
    display: flex;
    font-size: 0.875rem;
    font-weight: 300;
    color: $black;
    width: 100%;
    gap: 10px;
    height: $field-height;
    padding: 0.35rem 1rem 0.35rem 0.35rem;
    border: 1px solid rgba($border, 35%);
    border-radius: $field-radius;
    &--sm {
      height: $field-height-sm;
    }
    &--small {
      justify-content: center;
      align-items: center;
      padding: 0;
      border-style: dashed;
      height: $field-height-sm;
      .form__browsefile__info {
        padding: .5rem 1rem;
        justify-content: center;
        font-size: .55rem;
        font-weight: 400;
        color: $gray;
        cursor: pointer;
        .link {
          font-size: .75rem;
          font-weight: 500;
          text-decoration: none !important;
        }
      }
    }
    &--dashed {
      justify-content: center;
      align-items: center;
      padding: 0;
      border-style: dashed;
      height: 70px;
      .form__browsefile__info {
        padding: .5rem 1rem;
        justify-content: center;
        font-size: .75rem;
        font-weight: 400;
        color: $gray;
        cursor: pointer;
        .link {
          font-size: .75rem;
          font-weight: 500;
          text-decoration: none !important;
        }
      }
    }
    &--orderUrl {
      background: rgba($primary, 5%);
      justify-content: center;
      align-items: center;
      height: 145px;
      cursor: pointer;
    }
    &--disabled {
      background: rgba(210, 214, 219, 0.5019607843);
      cursor: not-allowed;
    }
    .form__input {
      padding: 0;
      border: 0;
      flex: 1 1;
      height: auto;
      border-radius: 0;
    }
    .form__browsefile {
      font-size: 0.875rem;
      font-weight: 400;
      color: $gray;
    }
    .btn {
      font-size: 0.875rem;
      font-weight: 500;
      min-width: 105px;
      cursor: pointer;
    }
    &__info {
      display: flex;
      align-items: center;
      gap: 8px;
      flex: 1 1;
      height: auto;
      .icon {
        width: 20px;
        height: 20px;
        flex: 0 0 20px;
      }
    }
  }
  &__browsefileBox {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 16px;
    .form__browsefile {
      padding: 0 1rem 0 0;
      height: auto;
      min-height: 55px;
      overflow: hidden;
      .btn {
        min-height: 1px;
      }
      &__value {
        display: flex;
        align-items: center;
        font-size: .75rem;
        font-weight: 500;
        color: $primaryBlack;
        gap: 16px;
        width: calc(100% - 22px);
        .icon {
          display: flex;
          align-items: center;
          justify-content: center;
          background: $lightgray;
          padding: .5rem;
          position: relative;
          flex: 0 0 55px;
          width: 55px;
          height: 55px;
          cursor: pointer;
          -webkit-transition: all 0.3s ease-out;
          transition: all 0.3s ease-out;
          &::after {
            content: '';
            background: rgba($black, 50%);
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            z-index: 1;
            opacity: 0;
            -webkit-transition: all 0.3s ease-out;
            transition: all 0.3s ease-out;
          }
          &:hover {
            &::after {
              opacity: 1;
            }
          }
          svg {
            flex: 0 0 20px;
            width: 20px;
            height: 20px;
          }
        }
        &__detail {
          display: flex;
          flex-direction: column;
          width: calc(100% - 70px);
          .para {
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: 
            vertical;overflow: hidden;
            margin-bottom: 0 !important;
          }
          .link {
            font-size: .75rem;
            font-weight: 500;
            color: $primaryBlack;
            &--primary {
              color: $primary;
            }
          }
          .progressBox {
            display: flex;
            align-items: center;
            gap: 12px;
            .progressbar {
              position: relative;
              background: #ECECEC;
              width: 272px;
              flex: 0 0 272px;
              height: 5px;
              border-radius: 20px;
              overflow: hidden;
              &.small {
                width: 100%;
                flex: 0 0 100%;
              }
              .progress {
                display: flex;
                align-items: center;
                justify-content: center;
                align-items: center;
                background: #50C878;
                font-size: .875rem;
                font-weight: 800;
                color: $white;
                position: absolute;
                height: 100%;
                left: 0;
                top: 0;
                border-radius: 20px;
              }
            }
          }
        }
      }
      &__closeBtn {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 0 0 20px;
        width: 20px;
        cursor: pointer;
      }
    }
    .btn {
      min-height: 44px;
    }
  }
  &__uploadfiled {
    display: none;
  }
  &__attachUrl {
    background: $white;
    padding: 1.5rem;
    box-shadow: 0px 1px 15.2px 0px #26617d33;
    margin-top: 0.5rem;
    &--transparent {
      padding: 0;
      box-shadow: none;
      .form__attachUrl__history {
        border-radius: 6px 6px 0 0;
        &__body {
          align-items: flex-start;
          min-height: 70px;
        }
      }
    }
    .flexRow {
      display: flex;
      align-items: center;
      gap: 16px;
      margin-bottom: 1rem;
      .btn {
        font-size: 0.75rem;
        font-weight: 400;
        min-width: 115px;
        height: 44px;
      }
    }
    &__history {
      width: 100%;
      border: 1px solid $lightgray;
      border-radius: 6px;
      &__header {
        padding: 0.5rem 1rem 1rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid $lightgray;
        gap: 10px;
        .item {
          font-size: 0.875rem;
          font-weight: 500;
          color: $darkGray;
        }
      }
      &__body {
        padding: 1rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        gap: 15px;
        height: 122px;
        overflow: hidden;
        overflow-y: auto;
        font-size: 0.875rem;
        font-weight: 500;
        color: $gray;
        .para {
          display: flex;
          align-items: center;
          gap: 8px;
          font-size: 0.875rem;
          font-weight: 500;
          color: $gray;
          .icon {
            display: flex;
            align-items: center;
            width: 24px;
            flex: 0 0 24px;
          }
        }
        .url {
          font-size: 0.875rem;
          font-weight: 300;
          color: #808080;
        }
        .btn__group {
          width: 100%;
          justify-content: space-between;
          .btn {
            background: $dangerLight;
            border-color: $dangerLight;
            min-width: 90px;
            font-size: 0.75rem;
            font-weight: 400;
          }
        }
      }
    }
  }
  &__SearchDropdown {
    background: $white;
    padding: 1rem;
    width: 100%;
    height: 220px;
    overflow-y: auto;
    border: 1px solid rgba($primaryBlack, 16%);
    box-shadow: 0px 1px 2.2px 0px #00000040;
    &__item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0.65rem 0;
      font-size: 0.875rem;
      font-weight: 400;
      color: $primaryBlack;
      border-bottom: 1px solid rgba($primaryBlack, 16%);
      gap: 8px;
      cursor: pointer;
      span {
        color: $secondary;
      }
      &:hover,
      &--selected {
        background: rgba(#e1e1e1, 31%);
        color: $lightGray;
      }
      &:last-child {
        border-bottom: none;
      }
    }
  }
  &__error {
    display: block;
    font-size: 0.75rem;
    font-weight: 400;
    color: #f05757;
    margin-top: 0.375rem;
    &--right {
      text-align: right;
    }
  }
  &__noteText {
    display: block;
    font-size: .75rem;
    font-weight: 400;
    color: rgba($primaryBlack, 50%);
    padding-top: .5rem;
  }
  &__button {
    margin-bottom: 0;
    .btn {
      padding: 0.625rem 1.125rem;
      font-size: 1.25rem;
      font-weight: 400;
      width: 100%;
      height: 56px;
      margin: 0.75rem 0 0 0;
    }
  }
  &--innersec {
    .form__section {
      margin-bottom: 2px;
      .formRow {
        .btn__group {
          margin-bottom: 15px;
        }
      }
    }
  }
  &__sectionGrid {
    display: flex;
    gap: 4px;
    .form__section {
      width: 100%;
      box-shadow: none;
      margin-top: .125rem;
      @media screen and (max-width: 992px) {
        width: calc(100% / 2);
      }
    }
    .formRow {
      &__2 {
        &.formRowMFull {
          grid-template-columns: repeat(1, minmax(0, 1fr));
       
          @media screen and (min-width: 1920px) {
            grid-template-columns: repeat(2, minmax(0, 1fr));
          }
        }
      }
      &__3 {
        grid-template-columns: repeat(1, minmax(0, 1fr));
   
        @media screen and (min-width: 1200px) {
          grid-template-columns: repeat(3, minmax(0, 1fr));
        }
      }
   
      &__4 {
        grid-template-columns: repeat(1, minmax(0, 1fr));
   
        @media screen and (min-width: 1200px) {
          grid-template-columns: repeat(2, minmax(0, 1fr));
        }
        @media screen and (min-width: 1920px) {
          grid-template-columns: repeat(4, minmax(0, 1fr));
        }
      }
    }
  }
  &__section {
    padding: 1.5rem 1.25rem;
    background-color: $white;
    box-shadow: 0px 2px 12px 0px #0000000f;
    margin-bottom: 20px;
    position: relative;
    @media screen and (max-width: 639px) {
      padding: 1rem;
    }
    &__title {
      font-size: 1.25rem;
      font-weight: 600;
      color: $primaryBlack;
      line-height: 24px;
      margin: 0 0 1rem 0;
      &--flex {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        .btn__group {
          .btn {
            line-height: 15px;
          }
        }
      }
      &--second {
        display: flex;
        align-items: flex-start;
        gap: 12px;
        font-size: 0.875rem;
        font-weight: 500;
        line-height: 20px;
      }
    }
    .btn {
      &__danger {
        background: $dangerLight;
        border-color: $dangerLight;
      }
    }
    .formRow {
      + .btn__group {
        padding-top: 0.5rem;
      }
    }
  }
  .finalMileInfoBox {
    padding: 1.5rem;
    border: 1px solid rgba($border, 35%);
    border-radius: $field-radius;
    display: flex;
    flex-direction: column;
    gap: 20px;
    .form__Field {
      display: flex;
      align-items: center;
      gap: 10px;
      .form__label {
        font-size: 1rem;
        font-weight: 600;
        color: $darkGray;
        flex: 0 0 200px;
        width: 200px;
        margin-bottom: 0;
      }
    }
  }
  .getQuoteBox {
    background: $white;
    padding: $field-padding-x;
    border: 1px solid rgba($border, 35%);
    border-radius: $field-radius;
    .title {
      font-size: 1rem;
      font-weight: 600;
      margin-bottom: 0;
    }
    .para {
      font-size: 1rem;
      font-weight: 400;
      color: $primaryBlack;
      margin-bottom: 10px;
    }
  }
  .uploadField {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 24px;
    @media screen and (max-width: 639px) {
      gap: 12px;
    }
  }
  .uploadImage {
    width: 140px;
    height: 140px;
    flex: 0 0 140px;
    position: relative;
    overflow: hidden;
    border: 2px solid $lightgray;
    border-radius: 50%;

    @media screen and (max-width: 639px) {
      width: 100px;
      height: 100px;
      flex: 0 0 100px;
    }

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
      &.logo{
        object-fit: contain;
      }
    }
    .picIcon {
      background: $primary;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      font-size: 3rem;
      font-weight: 700;
      color: $white;
      text-transform: uppercase;
      border-radius: 50%;
    }
  }
  .MuiAutocomplete-root {
    .MuiOutlinedInput-root {
      font-size: 0.875rem;
      font-weight: 300;
      min-height: $field-height-sm;
      padding: $field-padding-y $field-padding-x;
      border: 1px solid rgba($border, 35%);
      border-radius: $field-radius;
      .MuiOutlinedInput-input {
        padding: 0;
        font-size: 0.875rem;
        font-weight: 300;
      }
      .MuiOutlinedInput,
      fieldset {
        border: none !important;
      }
    }
  }
  .rc-time-picker-input {
    border: none;
  }
  .rc-time-picker-clear {
    right: 40px;
    top: 13px;
  }
  .rc-time-picker-clear-icon {
    &:after {
      font-family: cursive;
      font-size: 18px;
    }
  }
}
